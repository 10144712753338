<template>
    <div class="p-3 bg-gray-relative-100 mh-vh-100-nav">
        <SubHeader title="QR스캔"></SubHeader>
        <div>
            <h4>
                지류상품권의 핀번호 또는<br>핀코드를 인식하여 주세요
            </h4>
            <ul class="small my-4">
                <li class="d-flex lh-sm mb-1"><span class="text-gray-relative-500">지류 상품권을 결제후 남은 금액은 포인트로 충전됩니다.</span></li>
            </ul>
        </div>
        <div class="form-group mb-2 mt-5">
            <div class="d-flex ">
                <input class="form-control thick bg-body border-gray-relative-300" type="text" placeholder="핀코드" v-model="code">
                <div class="btn btn-secondary flex-shrink-0 small d-flex align-items-center ms-1"><span><i class="fal fa-qrcode"></i></span></div>
            </div>
        </div>
        <button class="rounded-3 btn thick btn-main w-100" @click="CouponPayment()">결제</button>
        <Loading v-if="loading"/>            
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");
import Loading from '@/components/common/Loading.vue'

export default {
    components: {
        SubHeader,Loading
    },
    data(){
        return{
            loading : false,
            code : '',
            payment_code: this.$route.params.code,
        }
    },
    methods:{
        CouponPayment(){
            const code = this.code;
            const payment_code = this.payment_code;

            if(code ==''){
                this.$alert("핀 코드를 확인해주세요.");
                return false;
            }

            const body = {code,payment_code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/shop/payment/CouponPayment',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$router.push({path : `/payment/receipt/${payment_code}`});
                        }else if(res.data.code=='100'){
                            this.$alert("이미 사용된 상품권입니다.<br>또는 상품권 핀번호를 확인해주세요.")
                        }else if(res.data.code=='300'){
                            this.$alert("상품권 한도를 초과되었습니다.");
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            );
            
        },
        Payment(){
            if(false){ //한도초과시
                this.$alert("한도가 초과되었습니다.").then( ()=>{
                    this.$router.push('/home')
                } )
            }else{
                this.$router.push('/payment/receipt')
            }
        }
    }
}
</script>

<style>

</style>